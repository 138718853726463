import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
   _toasts = new BehaviorSubject<{ text: string; classname: string; }[]>([]);

  get toasts$() {
    return this._toasts.asObservable(); // Expose as Observable for components to subscribe
  }

  show(text: string, options: { classname?: string;  } = {}) {
    const newToast = { text, classname: options.classname || '', delay: 100 };
    this._toasts.next([newToast]); // Push new toast into the array reactively
  }

  remove(toast: any) {
    const currentToasts = this._toasts.getValue();
    this._toasts.next(currentToasts.filter((t) => t !== toast));
  }
}
